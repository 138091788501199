import Principal from '../Principal/index';
import Mision from '../MissionObjectives/index';
import ObservatoryAreas from '../ObservatoryAreas/index';
import Links from '../Links/index';
import { Footer } from "../../components/Footer";


function home() {
  return (
    <>
      <Principal/>
      <Mision/>
      <ObservatoryAreas/>
      <Links/>
      <Footer/>
    </>
  );
}

export default home;