import React, { useRef } from 'react';
import './styles.css'; 
import Movil from '../../Images/web_micrositio_Observatorios_iconos--08-mobile.png';
import Web from '../../Images/web_micrositio_Observatorios_iconos--07-web.png';

const Iconos = () => {
  return (
      <>  
        <section className='conteiner--logo-web' id='conteiner--logo-web'>
            <img src={Web} alt="logo" />
        </section>
        <section className='conteiner--logo-movil' id='conteiner--logo-movil'>
            <img src={Movil} alt="logo" />
        </section>
      </>
    );
  }
export default Iconos;