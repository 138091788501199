import React from 'react';
import { Header } from "../../components/Header";
import './styles.css';
import LogoObservatorio from "../../Images/web_micrositio_Observatorios_iconos--04-obs-color.png";
import Flechas from "../../Images/web_micrositio_Observatorios_iconos--12.png";


const index = () => {
  return (
    <>
      <Header/>
      <main className='main--conteiner-principal'>
        <section className='section--container'>
            <h1 className='encodesans-black'>Observatorio de las Violencias y Desigualdades por Razones de Género </h1>
            <p >Un espacio de sistematización, análisis y producción de información sobre violencias y desigualdades de género</p>
            <p className='section--container-p encodesans-medium'>El Observatorio, creado por la Resolución 2020/6, se enmarca entre 
            las competencias asignadas al Ministerio de Capital Humano de la Nación como autoridad de aplicación de 
            la Ley N° 26.485 de Protección Integral para prevenir, sancionar y erradicar la violencia con las mujeres en los ámbitos
             en que se desarrollen sus relaciones interpersonales. 
            </p>

            <h2 className='section--container-parrafo encodesans-black'>
              <span><img className="flechas" src={Flechas} alt="icono"/>
              </span> 
              Misión y objetivos del Observatorio
            </h2>

        </section>
        <div className="Div--Contenedor-LogoObservatorio" id="Div--Contenedor-LogoObservatorio"> 
          <img className="LogoObservatorioHome" src={LogoObservatorio} alt="logo" />
        </div>
      </main>
    </>
  )
}

export default index