import React from 'react';
import './styles.css';
import { HeaderEjes } from '../../components/Header';
import { FooterEjes } from "../../components/Footer";
import  Search  from '../../components/Search/index';
import Nube from '../../Images/web_micrositio_Observatorios_iconos-_04-NUBE.png'
import RedFederal from '../../Images/web_micrositio_Observatorios_iconosbotones-15.svg';
import Actividades from  '../../Images/web_micrositio_boton-actividades.svg';
import DucumentoTrabajo from '../../Images/web_micrositio_Observatorios_iconosbotones-16.svg';
import  { useEffect } from "react";
import { Link } from 'react-router-dom';

function MyComponent() {
    useEffect(() => {
      window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
    }, []);


  return (
    <>
        <HeaderEjes titulo="Red Federal de Observatorios"/>
        <div>
            <div className='div--red'>
                <section className='div--conteiner-sections'>
                        <section className='section--red'>
                                <h1 className='encodesans-semiBold'>Red federal de Observatorios</h1>
                                <img className='imagenes' src={RedFederal} alt="imagenes del titulo red federal"/>
                                <h2 className='h2__redFederal encodesans-semiBold'>Resolución oficial de creación de la Red Federal de Observatorios</h2>
                
                                <a href="/downloads/ResolucionDeCreacionDeLaRedFederal(1).pdf"
                                    className='btn--blue'
                                    target="_blank">
                                    <img src={Nube} className='icono_download'/>
                                    Resolución oficial de la Red de Observatorios.pdf
                                </a>
                        </section>
                        <section className='section--grey'>
                                <hr/>
                                <h2 className='encodesans-semiBold'>Misión de la Red</h2>
                                <p className='section--grey-parrafo encodesans-medium'>Promover el fortalecimiento y la creación de los diferentes observatorios de violencia y desigualdades 
                                    por motivos de género que existan o puedan existir en los distintos niveles de Estado, nacional, provincial
                                    y municipal, de las organizaciones de la sociedad civil y universidades. 
                                </p>
                        </section>
                        <section className='section--grey'>
                            <h2 className='encodesans-semiBold'>Objetivos de la Red</h2>
                            <p>
                                <ul>
                                    <li className='encodesans-medium'>Promover la produción y sistematización de información sobre violencias y desigualdades
                                        de género a nivel federal, focalizando en el desarrollo de análisis de información estadística.
                                    </li>
                                    <li className='encodesans-medium'>
                                        Estableccer lineamientos para fortalecer y crear observatorios que aborden las problemáticas 
                                        relacionadas a las violencias y desigualdades de género.
                                    </li>
                                    <li className='encodesans-medium'>
                                        Brindar capacitaciones entorno a la producción, análisis e investigación sobre violencias y desigualdades
                                        de género, en articulación con la Subsecretaría de Formación, Investigación y Políticas Culturales para la 
                                        Igualdad del Ministerio de Capital Humano.
                                    </li>
                                    <li className='encodesans-medium'>
                                        Fomentar la articulación para el intercambio técnico y de información entre observatorios,
                                        centros de estudios, organismos públicos y actores de la sociedad civil entorno a la producción de información y
                                        conocimiento en materia de brechas y políticas de género, igualdad y diversidad.
                                    </li>
                                </ul>
                            </p>
                        </section>

                        <section className='conteiner--buttons-downloads'>

                                    <img className='imagenes' src={Actividades} alt="imagen del titulo actividades de la red"/>
                                    <h2 className='encodesans-semiBold h2__redFederal'> Encuentro de lanzamiento de la Red Federal de Observatorio de las 
                                    Violencias y desigualdades de género.Fecha: 15/12/2022
                                    </h2>
                                  <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/se-presento-la-red-federal-de-observatorios-de-las-violencias-y-desigualdades-por-razones"  target="_blank">
                                    Link a la nota
                                 </Link>
                                    <a href="/downloads/Minuta Lanzamiento de la Red Federal de Observatorios Versión final 6-01-23.pdf"
                                        target="_blank"
                                        className='btn--blue'
                                    >
                                        <img src={Nube} className='icono_download'/>
                                        Minuta Lanzamiento de la Red Federal de Observatorios Versión final 6-01-23.pdf
                                    </a>
                                    <h2 className='encodesans-semiBold h2__redFederal'> Primer Encuentro Nacional de la Red Federal de Observatorios de las violencias 
                                    y desigualdades por razones de género de 2023. Fecha 11/04/2023
                                    </h2>
                                   <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa:</h3> 
                                   <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/por-primera-vez-todas-las-regiones-del-pais-estan-representadas-en-la-red-federal-de#:~:text=El%20Ministerio%20de%20las%20Mujeres,Razones%20de%20G%C3%A9nero%20de%202023. "  target="_blank">
                                   Link a la nota
                                    </Link>
                                    <h3 className='encodesans-semiBold h3__redFederal' >  Minuta del Encuentro:</h3>
                                    <a href="/downloads/Minuta - Encuentro Red Federal 11-04-23.pdf"
                                        className='btn--blue'
                                        target="_blank">
                                        <img src={Nube} className='icono_download'/>
                                        Minuta - Encuentro Red Federal 11-04-23.pdf 
                                    </a>
                                    <h2 className='encodesans-semiBold h2__redFederal'>Capacitación:  Introducción en la Planificación y Seguimiento de las Políticas Públicas con Perspectiva de 
                                    Género y Diversidad de la Red Federal de Observatorios. Fecha: 8/05/2023 </h2>
                                   <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa: </h3> 
                                   <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/mas-de-80-referentes-de-observatorios-de-violencias-y-desigualdades-se-capacitaron-en"  target="_blank">
                                   Link a la nota
                                    </Link>
                                    <h2 className='encodesans-semiBold h2__redFederal'>Capacitación Presupuesto con Perspectiva de género. Fecha: 31/05/2023  </h2>
                                  
                                    <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa: </h3> 
                                    <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/referentes-de-observatorios-de-violencias-y-desigualdades-se-capacitaron-en-presupuesto-con"  target="_blank">
                                    Link a la nota
                                    </Link>
                                    <h2 className='encodesans-semiBold h2__redFederal'>Mesa de trabajo: "El trabajo con las violencias por motivos de género: datos, análisis e informes". Fecha: 17/08/2023 </h2>
                                    <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa: </h3> 
                                    <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/se-realizo-la-primera-mesa-de-trabajo-de-violencias-de-la-red-federal-de-observatorios-de"  target="_blank">
                                    Link a la nota
                                    </Link>                         
                                     <h3 className='encodesans-semiBold h3__redFederal'>Minuta de la mesa de trabajo: </h3> 
                                     <a href="/downloads/Minuta Red Federal -  Mesa de violencias - 17.08.23.pdf"
                                        className='btn--blue'
                                        target="_blank">
                                        <img src={Nube} className='icono_download'/>
                                        Minuta Red Federal - Mesa de violencias - 17.08.23.pdf 
                                    </a>
                                    <h2 className='encodesans-semiBold h2__redFederal'>  Mesa de Trabajo:"El abordaje de los observatorios/ instituciones de género sobre las masculinidades". Fecha: 19/09/2023   </h2>
                                    <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa: </h3> 
                                    <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/con-eje-en-las-masculinidades-se-realizo-la-segunda-mesa-de-trabajo-de-la-red-federal-de "  target="_blank">
                                    Link a la nota
                                    </Link>
                                    <h3 className='encodesans-semiBold h3__redFederal'>Minuta de la mesa de trabajo sobre masculinidades:  </h3> 
                                    <a href="/downloads/Minuta Masculinidades 19-09.pdf"
                                        className='btn--blue'
                                        target="_blank">
                                        <img src={Nube}/>
                                        Minuta de la mesa de trabajo sobre masculinidades.pdf
                                    </a>
                                    <h2 className='encodesans-semiBold h2__redFederal'>Mesa de Trabajo: "El abordaje de los observatorios/ instituciones de género sobre las masculinidades (II)". Fecha: 9/10/2023</h2>
                                    <h3 className='encodesans-semiBold h3__redFederal'>Nota de prensa: </h3> 
                                    <Link className="link-costumizado" to="https://www.argentina.gob.ar/noticias/la-red-federal-de-observatorios-realizo-una-nueva-mesa-de-trabajo-sobre-masculinidades"  target="_blank">
                                    Link a la nota
                                    </Link>
                                    <a href="/downloads/Minuta Red 9 de octubre (1).pdf"
                                        className='btn--blue'
                                        target="_blank">
                                        <img src={Nube}/>
                                        Minuta de la mesa de trabajo sobre masculinidades.pdf
                                    </a>
                                    {/* AQUI COMIENZA EL DOCUMENTO DE TRABAJO */}
                                    <img className='imagenes' src={DucumentoTrabajo} alt="imagen deltitulo de imagen de documentos de trabajo"/>
                                    <h3 className='encodesans-semiBold h3__redFederal'>Guía para la creación y la implementación de observatorios de género y diversidad</h3>
                                    <a href="/downloads/Minuta Red 9 de octubre (1).pdf"
                                        className='btn--blue'
                                        target="_blank">
                                        <img src={Nube}/>
                                        Guía para la creación y la implementación de observatorios.pdf
                                    </a>
                        </section>
                </section>       
                <Search/>  
                </div>
                <div> 
                <FooterEjes/>
                </div>
        </div>
    </>
  )
}

export default MyComponent