import React from 'react';
import './styles.css';
import  Iconos from '../../components/Iconos/index';


const index = () => {
    return (
        <div className='first--conteiner-mision'>
            <main className='main--conteiner-mision'>
                <div className='div--conteiner-mision'>
                    <h1>Misión</h1>
                    <p className='encodesans-semiBold'>
                        Fortalecer la gestión de la información, la sistematización de datos y la construcción de conocimiento para 
                        brindar insumos para el diseño, implementación y gestión de políticas públicas para prevenir y poner fin a las 
                        violencias y desigualdades de género.
                    </p>
                </div>  
                <div className='div--conteiner-objetivos'>
                        <h1>Objetivos</h1>
                        <li className='encodesans-semiBold'>Analizar y sistematizar información estadística y de otra índole sobre violencias y desigualdades de género, en  la compentencia de la Subsecretaria.</li>
                        <li className='encodesans-semiBold'>Capacitar y asistir técnicamente para el análisis y la publicación de datos con perspectiva de género y diversidad y cooperar con otras 
                            jurisdicciones y entidades del sector público nacional y subnacional. </li>
                        <li className='encodesans-semiBold'>Participar en la elaboración y publicación de informes en materia de género, igualdad y diversidad.</li>
                        <li className='encodesans-semiBold'>Diseñar y elaborar un sistema de indicadores sobre igualdad y diversidad, como así también en relación con las diversas modalidades y tipos de violencias de género.</li> 
                        <li className='encodesans-semiBold'>Coordinar acciones de fortalecimiento institucional y trabajo en red con los observatorios de violencia y desigualdad por razones de género que existan o puedan
                            existir a nivel provincial o municipal.</li>
                </div>
            </main>
            <Iconos/>
        </div>
    )
}

export default index