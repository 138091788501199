import React from 'react';
import './styles.css';
import ObservatorioMono from "../../Images/web_micrositio_Observatorios_iconos--03-obs-mono.png";



export const Header = () => {
  return (
    <header>
        <div className='header--title-container'>
            <img className="ObservatorioMono" src={ObservatorioMono} alt="logo" />
            <h1 className='encodesans-black'>Observatorio de las Violencias y Desigualdades por Razones de Género</h1>
        </div>
    </header>
  )
}


export const HeaderEjes = ( props ) => {
  return (
    <>
      <div className='div--conteiner-title'>
          <div className='header--title-container'>
              <a href="/" className="home-link" id="home-link">
                  <i className="fas fa-arrow-left"></i>
              </a>
              <h1>
                  Observatorio de las Violencias y Desigualdades por Razones de Género
              </h1>
          </div>
          <div className='header--subtitle-container'>
              <h2 className='EncodeSans-Medium'>
                  Observatorio de Géneros / {props.titulo}
              </h2>
          </div>
      </div>
    </>
  )
}
export default { Header, HeaderEjes }