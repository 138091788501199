import React from 'react';
import './styles.css';
import CapitalHumano from '../../Images/Capital _Humano_B_circular (1).png';
import { Link } from 'react-router-dom';
import DropdownMenu from '../../components/DropdownMenu/index';



export function Footer() {
  return (
    <section className='section--logos-footer'>
        <div className='logosMinisterio'>
          <img className='img-1' src={CapitalHumano} alt="logo del ministerio de capital humano" />
        </div>
    </section>
  )
}

export function FooterEjes() {
  return (
    <>
    <div className="div--footer">
        <section className='first--section'>
            <h1>Enlaces de interés</h1>
            <h2 className='first--h2 encodesans-black'>Marco Normativo</h2>
            <h2>sobre Observatorio</h2>
            <p className='encodesans-medium'>(CEDAW, Belen do para, Ley 26.485, Ley de Paridad en la Participación Política, entre otras)</p>
            <DropdownMenu/>
        </section>

        <section className='second--section encodesans-black'>
              <Link to="https://www.Argentina.Gob.Ar/generos/plan_nacional_de_accion_contra_las_violencias_por_motivos_de_genero " className='link--footer'>
                      Plan Nacional de Acción contra las Violencias por Motivos de Género 2020-2022
              </Link>
              <Link to="Https://www.Argentina.Gob.Ar/generos/plan-nacional-de-accion-contra-las-violencias-por-motivos-de-genero-2022-2024 " className='link--footer-second'>
                      Plan Nacional de Acción contra las Violencias por Motivos de Género 2022-2024
              </Link>
        </section>
        <section className='third--section encodesans-black'>
            <Link to="Https://www.Argentina.Gob.Ar/generos/plan-nacional-de-igualdad-en-la-diversidad " className='link--footer'>
                  Plan Nacional de Igualdad en la Diversidad 2021-2023
            </Link>
            </section>
        
    </div>
    <div>
        <Footer/>
    </div>
        </>
);
}

export default { Footer, FooterEjes }