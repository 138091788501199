import React from "react";
import "./styles.css";
import { HeaderEjes } from "../../components/Header";
import { FooterEjes } from "../../components/Footer";
import Search from "../../components/Search/index";
import { useEffect } from "react";

function MyComponent() {
  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
      <HeaderEjes titulo="Datos abiertos" />
      <div className="div--conteiner-data">
        <section className="div--conteiner-sections ">
          <h1 className="EncodeSans-Black">Datos abiertos de la Línea 144</h1>
          <p className="encodesans-medium">
            Aquí encontrarás datos públicos de la Línea 144 en formatos abiertos
            para que puedas usarlos y compartirlos, podés crear visualizaciones,
            aplicaciones e incorporarlos a distintas herramientas y proyectos.
            <hr className="hr-data" />
          </p>

          <div className="div--conteiner-link">
            <section className="conteiner--buttons-downloads">
              <a
                href="https://datos.gob.ar/dataset/generos-base-datos-linea-144"
                target="_blank"
              >
                Datos abiertos de la línea 144 - Internacional
              </a>
            </section>
          </div>
        </section>
        <Search />
      </div>

      <div>
        <FooterEjes />
      </div>
    </>
  );
}

export default MyComponent;
