import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';
import DropdownMenu from '../../components/DropdownMenu/index';


const index = () => {
  return (
    <>
        <main className='conteiner--enlaces'>
            <h1 className='conteiner--ejes-h1'> Enlaces de interés</h1>
                    <div className='main--conteiner-enlaces'>
                        <p className='parrafos--links'>
                            <h2 className='encore'>Marco Normativo</h2>
                            <DropdownMenu/>
                        </p>

                        <div className='div--container-plan'> 
                            <Link to="https://www.Argentina.Gob.Ar/generos/plan_nacional_de_accion_contra_las_violencias_por_motivos_de_genero " className='parrafo--plan encodesans-black'  target="_blank">
                                Plan Nacional de Acción contra las Violencias por Motivos de Género 2020-2022
                            </Link>
                            <Link to="Https://www.Argentina.Gob.Ar/generos/plan-nacional-de-accion-contra-las-violencias-por-motivos-de-genero-2022-2024 " className='parrafo--plan encodesans-black' target="_blank">
                                Plan Nacional de Acción contra las Violencias por Motivos de Género 2022-2024
                            </Link>
                            <Link to="Https://www.Argentina.Gob.Ar/generos/plan-nacional-de-igualdad-en-la-diversidad " className='parrafo--plan-last encodesans-black' target="_blank">
                                Plan Nacional de Igualdad en la Diversidad 2021-2023
                            </Link>
                        </div>
                    </div>
        </main>
    </>
  )
}

export default index