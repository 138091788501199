import React from 'react';
import './style.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const index = () => {
  return (
    <>
          <DropdownButton id="dropdown-basic-button" title="Internacional">
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/25000-29999/26305/norma.htm" target="_blank">
                Convención CEDAW
              </Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/120000-124999/122926/norma.htm" target="_blank">
                Protocolo Facultativo CEDAW</Dropdown.Item>
              <Dropdown.Item href="http://yogyakartaprinciples.org/principles-sp/about/" target="_blank">
                Principios de Yogyakarta </Dropdown.Item>
            </DropdownButton>
            <DropdownButton id="dropdown-basic-button" title="Regional">
              <Dropdown.Item 
              href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/35000-39999/36208/norma.htm" target="_blank">Convención de Belem do Pará</Dropdown.Item>
            </DropdownButton>
            <DropdownButton id="dropdown-basic-button" title="Nacional">
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/150000-154999/152155/norma.htm" target="_blank">Ley N°26.485 de Protección Integral</Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/320000-324999/322870/norma.htm" target="_blank">Modificatorias: Ley N°27.501</Dropdown.Item>

              
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/330000-334999/333514/norma.htm" target="_blank"> Modificatorias: Ley N°27.533</Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/315000-319999/318666/norma.htm" target="_blank">Ley N°27.499 “Ley Micaela” </Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/300000-304999/304794/norma.htm" target="_blank">Ley 27.412 "Paridad Electoral"</Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/345000-349999/346231/norma.htm" target="_blank">Ley N°27.610 “Ley de Acceso a IVE”</Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/195000-199999/197860/norma.htm" target="_blank">Ley N°26.743 “Ley de Identidad de género”</Dropdown.Item>
              <Dropdown.Item href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/350000-354999/351815/norma.htm" target="_blank">Ley N° 27.636 “Ley de cupo travesti trans”</Dropdown.Item>
              <Dropdown.Item href="https://www.boletinoficial.gob.ar/detalleAviso/primera/246657/20210708" target="_blank">Ley N° 27.635 "Equidad en la Comunicación"</Dropdown.Item>
            </DropdownButton>
    </>
  )
}

export default index