import React from 'react';
import { FooterEjes } from "../../components/Footer";
import { HeaderEjes } from "../../components/Header";
import  Search  from '../../components/Search/index';
import Nube from '../../Images/web_micrositio_Observatorios_iconos-_04-NUBE.png'
import "./styles.css";
import  { useEffect } from "react";

function MyComponent() {
    useEffect(() => {
      window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
    }, []);

  return (
    <>
    <HeaderEjes titulo="Violencias de género"/>
    <div className='div--conteiner-article-violence '>
        <div className='div--conteiner-article'>
            <article className='article'>
                <div>
                <h1>Violencias de Género</h1>
                <p className='paragraph--article encodesans-medium'>La violencia contra mujeres y LGBTI+ es toda conducta, acción u omisión, que de manera directa
                    o indirecta, tanto en el ámbito público como en el privado, basada en una relación desigual de poder,
                    afecte su vida, libertad, dignidad, integridad física, psicológica, sexual, económica o patrimonial, 
                    como así también su seguridad personal.
                </p>
                <p className='paragraph--article encodesans-medium'>La ley N° 26.485, sancionada en 2009, define seis tipos de violencias de género: física, psicológica, sexual, económica
                    y patrimonial, simbólica y política. Asimismo, señala ocho modalidades en las que se presentan estas violencias: 
                    doméstica, institucional, laboral, violencia contra la libertad reproductiva, obstétrica, mediática, violencia contra las 
                    mujeres en el espacio público y la violencia política-pública.</p>
                </div>
            </article>
            <main className='main--container'>
                <div>
                    <h2>Fichas estadísticas sobre violencias de género</h2>
                    <p  className='first--paragraph--main encodesans-semiBold'>
                        Las fichas reúnen la información pública disponible sobre las formas en que se expresan las distintas
                        modalidades de violencias de género.   
                    </p>
                    <div className='conteiner-button'>  
                        <a href="/downloads/fichasobs-violenciadomestica (1).pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia Doméstica.pdf
                        </a>
                        <a href="/downloads/ficha-violenciaLaboral.pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia Laboral.pdf
                        </a>
                        <a  href="/downloads/ficha-violenciaObstetrica.pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia Obstétrica.pdf
                            </a>
                        <a  href="/downloads/ficha-violenciaContraLaLibertadReproductiva.pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Contra la Libertad Reproductiva.pdf
                            </a>

                        <a  href="/downloads/FichasObs-ViolenciaMediatica-v9.pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia Mediática.pdf
                            </a>


                        <a href="/downloads/FichasObs-ViolenciaInstitucional.pdf" 
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia Institucional.pdf
                            </a>
                        <a href="/downloads/ficha-ViolenciaEP.pdf"
                            className='btn--download--fichas'
                            target="_blank">
                            <img src={Nube}/>
                            Ficha- Violencia en Espacio Público.pdf
                        </a>
                    </div>
                </div>
            </main>
            <hr/>
            <section className='container--informes'>
                <h3 className='container--informes--h3 encodesans-semiBold'>Situaciones de violencias atravesadas de las LGBTI+</h3>
                <a  href="/downloads/Informe - Situaciones de Violencia LGBTI+ - SICVG - MMGYD.pdf"
                    className='btn--violences'
                    target="_blank">
                    <img src={Nube}/>
                    Situaciones de Violencias LGBTI+ - SICVG - MMGYD.pdf
                </a>
                <h2 className='h2--informes encodesans-semiBold'>Informes sobre violencias de género</h2>
                <h3 className='container--informes--h3 encodesans-semiBold'>Violencia Obstétrica: Análisis de los registros de la Línea 144</h3>
                <p className='paragraph--informs encodesans-semiBold'>Este trabajo caracteriza y analiza las situaciones de violencia obstétrica a partir de las comunicaciones
                    recibidas a la Línea 144.
                </p>
                <a  href="/downloads/analisisLinea144-ViolenciaObstetrica.pdf"
                    className='btn--violences'
                    target="_blank">
                    <img src={Nube}/>
                    Violencia Obstétrica - Análisis de la Línea 144.pdf
                </a>
                    
            </section>
        </div>
        <Search/>
    </div>
   
    <div>   
        <FooterEjes/>
    </div>
    </>
  )
}

export default MyComponent