import React from 'react';



import "./styles.css";

const index = () => {
  return (
    <>
      <div className='div--conteiner-search'>
        
      </div>
    </>
  )
}

export default index