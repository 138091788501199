import React from 'react';
import './styles.css';
import { FooterEjes } from "../../components/Footer";
import { HeaderEjes } from "../../components/Header";
import  Search  from "../../components/Search/index";
import Nube from '../../Images/web_micrositio_Observatorios_iconos-_04-NUBE.png'
import { useEffect } from "react";

function MyComponent() {
  useEffect(() => {
    window.scrollTo(0, 0); // ajusta la posición del scroll a la parte superior de la página
  }, []);

  return (
    <>
      <HeaderEjes titulo="Desigualdades de género"/>
        <div className='conteiner--divs'>
          <div className='div--conteiner-article-inequalities'>
            <section className='section--desigualdades'>
                <h1>Desigualdades de género</h1>
                <p className='encodesans-medium'>Las desigualdades de género se manifiestan en el acceso diferenciado al efectivo y pleno
                    ejercicio de derechos de mujeres y LGBTI+ en diversos ámbitos: cultural, económico, laboral,
                    educativo, político, entre muchos otros.
                </p>
                <hr/>
            </section>
             <section className='section--inequalities'>
             <h2 className='encodesans-medium'>Informes sobre desigualdades de género </h2>
             <h3> La situación de las trabajadoras de casas particulares</h3>
             <a href="/downloads/Observatorio-CasasParticulares-v3 (1).pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                 Trabajadoras de casas particulares.pdf
              </a>
              <h3>Representaciones mediáticas del Ni Una Menos:</h3>
              <a href="/downloads/Representaciones NUM boceto v2 (1).pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                  Representaciones mediáticas del Ni Una Menos.pdf
              </a>
             


              <h3>Personas mayores en situación de violencia de género</h3>
              <p className='encodesans-medium'>
              Análisis cuali cuantitativo de las situaciones de violencias de las personas mayores a partir de los registros de la Línea
               144 y el Programa Acompañar
              </p>
              <a href="/downloads/Violencia Personas mayores-v7-alta.pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                 Violencia Personas mayores.pdf
              </a>
              <hr/>
            </section>
            <section className='section--inequalities'>
              <h3>Brecha digital de género</h3>
              <p className='encodesans-medium'>Qué es la brecha digital de género; cuáles son sus dimensiones y su impacto en la 
                vida de mujeres y LGBTI+
              </p>
              <a href="/downloads/brecha-digital.pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                  Brecha digital de género.pdf
              </a>
              <hr/>
            </section>

            <section className='section--inequalities'>
              <h3>La participación de las mujeres en el trabajo, el ingreso y la producción</h3>
              <p className='encodesans-medium'>Análisis de las desigualdades de género en el mundo del trabajo y su incidencia en los hogares.
              </p>
              <a href="/downloads/informe-desigualdad_en_el_trabajo-igualar-1er_trimestre_2023-v4.pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                  Participación de las mujeres en el trabajo.pdf
              </a>
              <hr/>
            </section>

            <section className='section--inequalities'>
              <h3>Monitoreo Género y Política: campaña electoral Paso 2021</h3>
              <p className='encodesans-medium'>Análisis de discursos mediáticos y representaciones de las candidaturas PASO 2021.
              </p>
              <a href="/downloads/monitoreo_genero_y_politica.pdf"
                  className='a-inequalities'
                  target="_blank">
                  <img src={Nube} className='icono_download'/>
                  Monitoreo género y politica.pdf
              </a>
            </section>
          </div>
          <Search/>
        </div>
        <div>
          <FooterEjes/>
        </div>
    </>
  )
}
export default MyComponent