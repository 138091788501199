
import Home from './pages/Home/home';
import GenderViolence from './pages/GenderViolence/index';
import GenderInequalities from './pages/GenderInequalities/index';
import NetworkObservatories from './pages/NetworkObservatories/index';
import OpenData from './pages/OpenData/index'
import './fonts/EncodeSans-Black.ttf';
import './fonts/EncodeSans-Medium.ttf';
import './fonts/EncodeSans-SemiBold.ttf';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/Violenciasdegenero" element={< GenderViolence/>} />
        <Route path="/Desigualdadesdegenero" element={< GenderInequalities/>} />
        <Route path="/Redfederaldeobservatorios" element={< NetworkObservatories/>} />
        <Route path="/Datosabiertos" element={< OpenData/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;