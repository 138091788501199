import React from 'react';
import   './styles.css';
import { Link } from 'react-router-dom';

const index = () => {
  return (
    
        <main className='conteiner--ejes'>
            <h1 className='encodesans-black'>Ejes de trabajo del Observatorio</h1>
            <div className='main--conteiner-areas'>

                <section className='section--conteiner-first-ejes'>
                    <div className='conteiner-div-letters-blue'>
                        <a  className='div-letters-blue'>
                            <Link to={'/Violenciasdegenero'} className='encodesans-black link-b' target="_blank">Violencias de género</Link>
                        </a>
                    </div>

                    <div className='conteiner-div-letters-aqua'>
                        <a className='div-letters-aqua'>
                            <Link to={'/Desigualdadesdegenero'} className='encodesans-black link-a' target="_blank">Desigualdades de género</Link>
                        </a>
                    </div>
                </section>

                <section className='section--conteiner-second-ejes'>
                    <div className='conteiner-div-letters-blue'>
                        <a  className='div-letters-blue'>
                            <Link to={'/Redfederaldeobservatorios'} className='encodesans-black link-b' target="_blank" >Red Federal de Observatorios</Link>
                        </a>
                    </div>
                    <div className='conteiner-div-letters-aqua'>
                        <a  className='div-letters-aqua'>
                            <Link to={'/Datosabiertos'} className='encodesans-black link-a' target="_blank">Datos Abiertos</Link>
                        </a>
                    </div>
                </section>
                
            </div>
        </main>

  )
}

export default index;